import { Box, Table, TableContainer, TablePagination } from '@mui/material'
import EnhancedTableHead from './EnhancedTableHead'
import { EnhancedTableProps } from './table.types'

const EnhancedTable = <T,>({
  rows,
  rowCount = -1,
  headCells,
  order,
  orderBy,
  selected,
  page = 0,
  rowsPerPage,
  rowsPerPageOptions,
  next_cursor,
  previous_cursor,
  onRequestSort,
  onSelectAllClick,
  onChangePage,
  onChangeRowsPerPage,
  children,
}: EnhancedTableProps<T>) => {
  return (
    <Box sx={{ overflow: 'auto', margin: -3 }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelDisplayedRows={() => ''}
        slotProps={{
          actions: {
            nextButton: {
              disabled: !next_cursor,
            },
            previousButton: {
              disabled: !previous_cursor,
            },
          },
        }}
        data-testid="table-pagination"
      />
      <TableContainer
        sx={{
          maxHeight: 600,
          '&::-webkit-scrollbar': {
            display: 'none', // Hides the scrollbar for WebKit-based browsers
          },
          scrollbarWidth: 'none', // Hides the scrollbar for Firefox
        }}
      >
        <Table aria-labelledby="tableTitle" size={'medium'} stickyHeader data-testid="table">
          <EnhancedTableHead<T>
            headCells={headCells}
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={onSelectAllClick}
            onRequestSort={onRequestSort}
            rowCount={rows.length}
          />
          {children}
        </Table>
      </TableContainer>
    </Box>
  )
}

export default EnhancedTable
