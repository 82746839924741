import { useMemo } from 'react'
import { TableBody, TableRow, TableCell, Checkbox, Typography } from '@mui/material'
import TaskTypeChip from './TaskTypeChip'
import PopupMenuList from './PopupMenuList'
import PopupAssignMenuList from './PopupAssignMenuList'
import { EnhancedTableBodyProps } from '../qc_task_list.types'
import QueryStatusChip from './QueryStatusChip'
import TaskStateChip from './TaskStateChip'
import { formatTaskDate, getComparator } from '../utils'
import { UNSELECATBLE_TASK_STATUS } from '@qc_task_list_view/constant'

export default function EnhancedTableBodyTaskManager({
  rows,
  order,
  orderBy,
  selected,
  handleClick,
  assigneeList,
  authedUserId,
}: EnhancedTableBodyProps) {
  const isSelected = (id: string | null | undefined) => selected?.indexOf(id) !== -1

  const visibleRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, rows])

  return (
    <TableBody>
      {visibleRows.map((row, index) => {
        const isItemSelected = isSelected(row.task_id)
        const labelId = `enhanced-table-checkbox-${index}`

        return (
          <TableRow
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.task_id}
            selected={isItemSelected}
            sx={{
              borderBottom: '1px solid rgba(220, 227, 229, 1)',
            }}
          >
            <TableCell padding="checkbox" data-testid="checkbox-cell">
              <Checkbox
                color="primary"
                checked={isItemSelected}
                disabled={UNSELECATBLE_TASK_STATUS.includes(row.task_state)}
                onClick={(event) => handleClick && handleClick(event, row.task_id)}
                inputProps={{
                  'aria-labelledby': labelId,
                }}
                data-testid="checkbox"
              />
            </TableCell>
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="site-pseudo-id-cell">
              {row.site.site_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="subject-pseudo-id-cell">
              {row.site.site_pseudo_id} - {row.subject.subject_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="timepoint-name-cell">
              {row.timepoint.name}
            </TableCell>
            <TableCell align="left" data-testid="task-type-cell">
              <TaskTypeChip taskType={row.task_type} />
            </TableCell>
            <TableCell align="left" data-testid="task-state-cell">
              <TaskStateChip taskState={row.task_state} />
            </TableCell>
            <TableCell align="left" data-testid="query-status-cell">
              <QueryStatusChip queryStatus={row.query_status} />
            </TableCell>
            <TableCell align="left" data-testid="create-date-cell">
              {formatTaskDate(row.create_date)}
            </TableCell>
            <TableCell align="left" data-testid="assignee-cell">
              {UNSELECATBLE_TASK_STATUS.includes(row.task_state) ? (
                <Typography
                  sx={{
                    cursor: 'not-allowed',
                  }}
                >
                  {row.assignee?.user_name}
                </Typography>
              ) : (
                <PopupAssignMenuList
                  assigneeList={assigneeList || []}
                  tasksAndAssignees={[{ taskId: row.task_id, assignee: row.assignee }]}
                  currentAuthedUserId={authedUserId || ''}
                />
              )}
            </TableCell>
            <TableCell align="right" data-testid="actions-cell">
              <PopupMenuList />
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
