import { TabConfig } from '@common/components/Tab/tabs.types'
import ReaderTaskListTableMyTasks from './pages/ReaderTaskListTableMyTasks'
import ReaderTaskListTableTaskPool from './pages/ReaderTaskListTableTaskPool'
import ReaderTaskListTableCompletedTasks from './pages/ReaderTaskListTableCompletedTasks'
import ReaderTaskListTableSkippedTasks from './pages/ReaderTaskListTableSkippedTasks'

export enum ReaderTaskListTabsKeyEnum {
  MY_TASKS = 'MY_TASKS',
  TASK_POOL = 'TASK_POOL',
  SKIPPED = 'SKIPPED',
  COMPLETE = 'COMPLETE',
}

export type ReaderTaskListTabsKey = ReaderTaskListTabsKeyEnum

export const READER_TASK_LIST_TABS: Record<ReaderTaskListTabsKey, TabConfig> = {
  MY_TASKS: { label: 'My tasks', content: <ReaderTaskListTableMyTasks /> },
  TASK_POOL: { label: 'Task pool', content: <ReaderTaskListTableTaskPool /> },
  SKIPPED: { label: 'Skipped', content: <ReaderTaskListTableSkippedTasks /> },
  COMPLETE: { label: 'Complete', content: <ReaderTaskListTableCompletedTasks /> },
}

export const OHIF_DEV_LINK =
  'https://ohif.pbeat03002010-prod-4297-dev-gc.on-gcloud.bayer.com/blinded?StudyInstanceUIDs=2.16.840.1.114362.1.11972228.22789312658.616067305.306.2'
