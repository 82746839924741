import EnhancedTabs from '@common/components/Tab/EnhancedTabs'
import { useUserGroup } from '@common/hooks/useUserGroup'
import { useEffect } from 'react'
import getRoutes from '@qc_task_list_view/utils'
import { CurrentActiveTabProvider } from '@common/context/CurrentActiveTabProvider'
import { QC_TASK_LIST_TABS } from '@qc_task_list_view/constant'
import { TabOptionalComponentProvider } from '@common/context/TabOptionalComponentProvider'

export default function QcTaskListTabs() {
  const { setCurrentUserGroup, setNavigationRoutes } = useUserGroup()

  useEffect(() => {
    setCurrentUserGroup('IQM')
    setNavigationRoutes(getRoutes())
  }, [])

  return (
    <CurrentActiveTabProvider>
      <TabOptionalComponentProvider>
        <EnhancedTabs tabs={QC_TASK_LIST_TABS} />
      </TabOptionalComponentProvider>
    </CurrentActiveTabProvider>
  )
}
