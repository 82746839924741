import { useState, MouseEvent, ChangeEvent, useEffect } from 'react'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import EnhancedTableBodyTaskPool from '../components/EnhancedTableBodyTaskPool'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { GetQcTasksQcTasksGetData, Task } from '@common/config/api/client'
import { usePaginatedTasks } from '@qc_task_list_view/hooks/usePaginatedTasks'
import { DEFAULT_STUDY } from '@common/constants/study'
import { ROW_PER_PAGE_OPTIONS } from '@common/constants/table'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth'
import { useTabOptionalComponent } from '@common/hooks/useTabOptionalComponent'
import { Button } from '@mui/material'

const headCells: readonly HeadCell<Task>[] = [
  { id: 'task_pseudo_id', disablePadding: true, label: 'Blind Code' },
  { id: 'site', disablePadding: false, label: 'Site ID' },
  { id: 'subject', disablePadding: false, label: 'Subject ID' },
  { id: 'timepoint', disablePadding: false, label: 'Timepoint' },
  { id: 'task_type', disablePadding: false, label: 'Task Type' },
  { id: 'task_state', disablePadding: false, label: 'Task Status' },
  { id: 'query_status', disablePadding: false, label: 'Query Status' },
  { id: 'create_date', disablePadding: false, label: 'Create Date' },
]

export default function QcTaskListTableTaskPool() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Task | string>('task_pseudo_id')
  const [selected, setSelected] = useState<readonly (string | null | undefined)[]>([])
  const [page, setPage] = useState(0)
  const { user } = useExtendedAuth()
  const { setOptionalComponent, setIsComponentVisible, setIsComponentUsed } = useTabOptionalComponent()

  const [paginationParams, setPaginationParams] = useState<GetQcTasksQcTasksGetData['query']>({
    limit: ROW_PER_PAGE_OPTIONS[2],
    next_cursor: null,
    previous_cursor: null,
    category: 'task_pool',
    study_id: DEFAULT_STUDY.study_id,
    asc_order: true,
    order_by_field: 'created_at',
  })

  useEffect(() => {
    setIsComponentVisible(false)
    setIsComponentUsed(false)
    setOptionalComponent(
      <Button variant="contained" size="medium" color="primary">
        Assign tasks to me
      </Button>,
    )
  }, [])

  const { data, error, isLoading } = usePaginatedTasks(
    ['QCTasksList', 'TaskPool', `${page}`, `${paginationParams.limit}`],
    paginationParams,
  )

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof Task) => {
    const isAsc = orderBy.split('.')[0] === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')

    if (property === 'site') {
      setOrderBy('site.site_pseudo_id')
    } else if (property === 'subject') {
      setOrderBy('subject.subject_pseudo_id')
    } else if (property === 'timepoint') {
      setOrderBy('timepoint.name')
    } else {
      setOrderBy(property)
    }
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.data?.items?.map((n: Task) => n.task_id)
      if (newSelected && newSelected.length > 0) {
        setSelected(newSelected)
        setIsComponentVisible(true)
      }
      return
    }
    setSelected([])
    setIsComponentVisible(false)
  }

  const handleClick = (_event: MouseEvent<unknown>, id?: string | null) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: readonly (string | null | undefined)[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setIsComponentVisible(newSelected.length !== 0)
    setSelected(newSelected)
  }

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      // Moving to next page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: data?.data?.pagination.next_cursor,
        previous_cursor: null,
      }))
      setPage(page + 1)
    } else if (newPage < 0) {
      // Moving to previous page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: null,
        previous_cursor: data?.data?.pagination.previous_cursor,
      }))
      setPage(page - 1)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  return (
    <EnhancedTable<Task>
      rows={data?.data?.items || []}
      headCells={headCells}
      order={order}
      orderBy={orderBy.split('.')[0] as keyof Task}
      selected={selected}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      rowsPerPage={paginationParams.limit || 0}
      next_cursor={data?.data?.pagination.next_cursor}
      previous_cursor={data?.data?.pagination.previous_cursor}
      onRequestSort={handleRequestSort}
      onSelectAllClick={handleSelectAllClick}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
    >
      <EnhancedTableBodyTaskPool
        rows={data?.data?.items || []}
        order={order}
        orderBy={orderBy}
        selected={selected}
        handleClick={handleClick}
        authedUserId={user?.profile.sub}
      />
    </EnhancedTable>
  )
}
