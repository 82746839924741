import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { SyntheticEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { a11yProps } from './utils'
import { NavTabsProps } from './tabs.types'

export default function EnhancedNavTabs({ routes }: NavTabsProps) {
  const [value, setValue] = useState(0)

  useEffect(() => {
    const currentPath = location.pathname
    const currentIndex = routes.findIndex((route) => currentPath.includes(route.path))

    if (currentIndex !== -1) {
      setValue(currentIndex)
    }
  }, [routes])

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Tabs value={value} onChange={handleChange} aria-label="enhanced tabs" data-testid="tabs">
        {routes.map((route, index) => (
          <Tab
            key={index}
            data-testid={`tab-${index}`}
            component={Link}
            to={route.path}
            sx={{
              borderRadius: 1,
              color: 'text.secondary',
              typography: 'button',
            }}
            label={route.label}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </Box>
  )
}
